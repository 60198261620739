@import "./assets/styles/partials/_colors.scss";
@import "./assets/fonts/overpass/overpass.css";
@import "./assets/styles/shared.scss";
@import "./assets/styles/overrides.scss";
@import "./assets/styles/libs.scss";
@import "./assets/styles/partials/components/components.scss";

body {
  margin: 0;
  font-family: "overpass", sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #eff1f5;
  height: 100vh;

}

*::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
  height: 6px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.44, #2a324b), color-stop(0.72, #2a324b), color-stop(0.86, #2a324b));
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #c2c2c2;
  background-color: #ffffff;
  border-radius: 10px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;

  &:hover {
    color: #6282e3 !important;
    transition: all 0.14s;
  }
}

.main-loader {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("./assets/images/loader.svg");
  background-position: center;
  background-repeat: no-repeat;
  font-size: 14px;
  padding: 100px 0;

  span {
    margin-top: 100px;
    font-weight: 500;
    font-size: 16px;
    color: #ff410c;
  }
}