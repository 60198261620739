.dashboard-wrap {
    padding-top: 72px;

    .btn {
        i {
            font-size: 20px !important;
            margin: 0 4px;
        }
    }

    .btn-info {
        // padding: 10px !important;
        border: 1px solid #d1d1d1 !important;
        // font-size: 14px !important;
    }

    select {
        border: none;
        height: 100%;
        outline: none !important;
        box-shadow: none !important;
        border: 1px solid #c7ccdb;
        padding: 10px 15px;
        padding-right: 30px;

        &:not(:disabled) {
            background-color: white !important;
        }

    }

    .form-group label {
        color: black !important;
        font-weight: bolder !important;
    }

    .card {
        border-radius: 10px !important;
        box-shadow: 0px 0px 7px -5px #00000029 !important;
        border: none !important;
    }

    .dashboard-loader {
        width: 100%;
        left: 0;
        top: 0;
        height: 100%;
        background: #eff1f5;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url("../../../images/loader.svg");
        background-position: center;
        background-repeat: no-repeat;
        font-size: 14px;
        z-index: 9998;
        padding-top: 130px;
    }

    .box-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url("../../../images/Infinity-loader.svg");
        background-position: center;
        background-repeat: no-repeat;
        font-size: 14px;
        padding: 40px 0px;
    }
    input.form-control{
        padding: 13px 15px;
    }
}

.dashboard-table {
    table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 15px;

        th {
            padding: 16px 12px;
            color: #00233f;
        }

        tbody {
            tr {
                margin: 11px;
                border-radius: 10px;
                box-shadow: 0px 0px 14px -6px #00000024;

                &:hover {
                    transform: scale(1.02);
                    transition: all 0.2s;
                }
            }

            td {
                background: #fdfdfd;
                font-size: 15px;

                &:first-child {
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }

                &:last-child {
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }
        }

        .button-wrapper-cell {
            width: 140px;
        }

        .btn-absolute-table-cell {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &.selected-cell {
                background: #e4e9f4;
            }
        }
    }

    .page-item {
        .page-link {
            border-radius: 50% !important;
            background: transparent;
            border: none;
            margin: 0 6px;
            padding: 2px 4px;
            font-size: 15px;
            color: #6c6c6c;
            font-weight: bold;

            &.bordered {
                border: 2px solid #555555;
                font-size: 14px;
                padding: 2px 8px;
            }

            &[disabled] {
                color: #aeaeae !important;
                border-color: #aeaeae !important;
            }
        }

        &.active {
            .page-link {
                background: transparent;
                color: #370dba;
                text-decoration: underline;
                text-underline-offset: 3px;
                font-weight: bold;
            }
        }
    }

    input:checked {
        background-color: #002340 !important;
        border-color: #002340 !important;
    }

    


   

    &:not(.no-cell-padding) td {
        padding: 16px 12px;
    }
}
.modal-table .dashboard-table{
    max-height: 340px;
    overflow-y: auto;
    overflow-x: hidden;
}
.table-no-result {
    padding: 14px;
    background: #fcfcfc;
    font-size: 14px;
    margin: 20px 0;
    text-align: center;
    border-radius: 3px;
}
.btn-table-cell {
    background: none;
    border: none;
    color: black;
    font-weight: 500;
    cursor: pointer;

    &:hover {
        color: #00075f;
        transition: all 0.2s;
    }
}

.btn-full-back {
    color: white !important;
    padding: 13px 14px;
    font-size: 19px;

    &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    &.btn-bg-success {
        background-color: #218600;

        &:hover {
            background-color: #1d6f02;
            transition: all 0.2s;
        }
    }

    &.btn-bg-danger {
        background-color: #f33400;

        &:hover {
            background-color: #d62e00;
            transition: all 0.2s;
        }

    }
}
.inside-table {
    border-spacing: 0 !important;

    tr {
        margin: 0 !important;
        box-shadow: none !important;
        border-radius: 0 !important;

        &:hover {
            transition: unset !important;
            transform: unset !important;

        }
    }

    th {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        visibility: hidden;
        font-size: 0.4rem !important;
    }

    td {
        padding: 0 12px !important;
        padding-bottom: 10px !important;
    }

}

.dashboard-seperation {
    content: "";
    display: inline-block;
    width: 100%;
    left: 0.5em;
    vertical-align: 1px;
    border-bottom: 1px solid;
    color: #b3b7c3;
}

.dashboard-cancel-button {
    display: block;
    text-align: center;
    cursor: pointer;
    color: #0e1160;
    font-size: 15px;
    font-weight: 500;
    margin: 10px 0;
}

@media (max-width: 992px) {
    .dashboard-table {
        overflow-y: auto;
    }
}


.dashboard-nav {
    .dropdown-menu {
        margin-left: -10px !important;
    }
    #nav-dropdown {
        color: white !important;
    }
    
}

.collapse-section-title {
    margin-top: 25px;
    cursor: pointer;

    div {
        position: relative !important;
        display: inline-block;
        margin: 0px 2px;
        font-size: 15px;
        top: unset !important;
        right: unset !important;
        &.number-circle{
            background-color: #022341 !important;
        }

    }

    &:hover {
        transform: scale(1.01);
        transition: all 0.2s;
    }

    svg {
        font-size: 18px;
    }
}