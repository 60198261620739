.header-wrap {
    padding: 10px 50px;
    background: #0A223E;
    height: 90px;

    &.light-header {
        color: white;
        position: absolute;
        width: 100%;

        .header-action {
            color: white !important;
        }
    }

    &.dark-header {
        background: white;
        color: #2a324b;
        border: 1px solid #c7ccdb;
        border-top: none;

        .header-action {
            color: #2a324b !important;
        }
    }

    .header-actions-wrap {
        display: flex;
        align-items: center;

        .header-action {
            display: flex;
            text-decoration: none;
            flex-direction: column;
            align-items: center;
            text-align: center;
            font-size: 15px;
            cursor: pointer;

            &.menu-action {
                display: none;
            }
        }

        .header-customer-type-wrap {
            font-size: 14px;

            a {
                cursor: pointer;

                &.selected {
                    font-weight: 700;
                }
            }
        }
    }

    .header-logo-wrap {
        img {
            max-width: 250px;
            margin-bottom: 10px;
        }
    }

    @media (max-width: 768px) {
        .header-logo-wrap {
            img {
                max-width: 165px !important;
            }
        }
    }

    .responsive-menu-items {
        display: none;
    }

    .header-icon {
        display: flex;
        justify-content: center;
        font-size: 25px;
        margin-bottom: 5px;
    }
}

@media (max-width: 991px) {
    .header-actions-wrap {
        .header-customer-type-wrap {
            display: none !important;
        }

        >a:nth-child(odd) {
            display: none !important;
        }

        .header-action {
            flex-direction: row !important;

            .header-icon {
                margin-right: 9px;
            }
        }
    }

    .header-wrap {
        padding: 5px 45px !important;
        padding-right: 10px !important;
        height: 68px !important;
        z-index: 1000;

        &.fixed-header {
            position: fixed;
            width: 100%;
            top: 0;
        }
    }

    .responsive-menu-items {
        display: block !important;
    }

    .dark-header {
        .navbar-collapse {
            display: none;

            a {
                color: white;
            }
        }
    }
}

@media(max-height: 390px) {
    .header-wrap {
        height: 68px !important;
        padding: 2px 10px 10px 45px !important;
    }
}