@font-face {
  font-family: "overpass";
  src: url("overpass-thin.eot"); /* IE9 Compat Modes */
  src: url("overpass-thin.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("overpass-thin.woff2") format("woff2"), /* Super Modern Browsers */
       url("overpass-thin.woff") format("woff"), /* Pretty Modern Browsers */
       url("overpass-thin.ttf")  format("truetype"); /* Safari, Android, iOS */
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "overpass";
  src: url("overpass-extralight.eot");
  src: url("overpass-extralight.eot?#iefix") format("embedded-opentype"),
    url("overpass-extralight.woff2") format("woff2"),
    url("overpass-extralight.woff") format("woff"),
    url("overpass-extralight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "overpass";
  src: url("overpass-light.eot");
  src: url("overpass-light.eot?#iefix") format("embedded-opentype"),
    url("overpass-light.woff2") format("woff2"),
    url("overpass-light.woff") format("woff"),
    url("overpass-light.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "overpass";
  src: url("overpass-regular.eot");
  src: url("overpass-regular.eot?#iefix") format("embedded-opentype"),
    url("overpass-regular.woff2") format("woff2"),
    url("overpass-regular.woff") format("woff"),
    url("overpass-regular.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "overpass";
  src: url("overpass-italic.eot");
  src: url("overpass-italic.eot?#iefix") format("embedded-opentype"),
    url("overpass-italic.woff2") format("woff2"),
    url("overpass-italic.woff") format("woff"),
    url("overpass-italic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "overpass";
  src: url("overpass-semibold.eot");
  src: url("overpass-semibold.eot?#iefix") format("embedded-opentype"),
    url("overpass-semibold.woff2") format("woff2"),
    url("overpass-semibold.woff") format("woff"),
    url("overpass-semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "overpass";
  src: url("overpass-bold.eot");
  src: url("overpass-bold.eot?#iefix") format("embedded-opentype"),
    url("overpass-bold.woff2") format("woff2"),
    url("overpass-bold.woff") format("woff"),
    url("overpass-bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "overpass";
  src: url("overpass-bold-italic.eot");
  src: url("overpass-bold-italic.eot?#iefix") format("embedded-opentype"),
    url("overpass-bold-italic.woff2") format("woff2"),
    url("overpass-bold-italic.woff") format("woff"),
    url("overpass-bold-italic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "overpass";
  src: url("overpass-extrabold.eot");
  src: url("overpass-extrabold.eot?#iefix") format("embedded-opentype"),
    url("overpass-extrabold.woff2") format("woff2"),
    url("overpass-extrabold.woff") format("woff"),
    url("overpass-extrabold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
