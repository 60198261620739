.occupancy-calendar-wrap {
    padding-top: 40px;

    .occupancy-calendar-hints {
        .calendar-hint-wrap {
            display: inline-block;
            margin: 0 20px;
            position: relative;
            font-size: 13px;

            &:before {
                content: "";
                position: absolute;
                height: 10px;
                width: 10px;
                top: 4px;
                left: -15px;
                border-radius: 50%;
            }

            &.available-hint:before {
                background: #2cb36085;
            }

            &.partly-hint:before {
                background: #f9ce3c94;
            }

            &.full-hint:before {
                background: #d433398f;
            }

            &.locked-hint:before {
                background: #c6cfe9;
            }

            &.manually-locked-hint:before {
                background: #e4c6e9;
            }

            &.standby-hint:before {
                background: #ff9933;
            }

            &.external-hint:before {
                background: #efa7be;
            }
        }
    }

    .calendar-sidebar-wrap {
        width: 17%;
        padding: 10px;
        padding-right: 20px;

        .calendar-buttons-wrap {
            padding: 46px 0;
        }

        .cars-wrap {
            width: 100%;

            .car-item {
                padding: 10px 11px;
                background: white;
                border-radius: 8px;
                width: 100%;
                line-height: 21px;
                margin: 12px 0;
                height: 63px;

                span {
                    &:first-child {
                        display: block;
                        font-weight: bold;
                    }

                    &:last-child {
                        font-size: 13px;
                    }
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .calendar-wrap {
        display: flex;
        flex-direction: column;
        width: 83%;
        position: relative;

        .calendar-controls {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: space-between;
            top: 78px;

            button {
                line-height: 2px;
                border-radius: 50%;
                padding: 0px 4px;
                height: 26px;
                border-width: 2px;

                &:first-child {
                    margin-left: -40px;
                }

                &:last-child {
                    margin-right: -65px;
                }

                svg {
                    font-size: 14px;
                }
            }
        }

        .weeks-wrap {
            width: 100%;
            display: flex;
        }

        span.month_name {
            display: block;
            margin-top: -1.4em;
        }

        .week-wrap {
            .week-content {
                .week-data-wrap {
                    display: flex;
                    width: 100%;

                    .week-data-column {
                        display: flex;
                        width: 52px;
                        flex-direction: column;
                    }

                    .week-data-row {
                        display: flex;
                    }

                    .data-cell {
                        height: 75px;
                        width: 100%;
                        border-right: 1px solid #e6e6e6;
                        position: relative;
                        cursor: pointer;

                        &:before {
                            content: "";
                            position: absolute;
                            width: 102%;
                            height: 65px;
                            margin-top: 10px;
                        }

                        &.selected:before {
                            transform: scaleY(1.05);
                            box-shadow: 0px 0px 3px 0px #0000001f;
                            transition: all 0.2s;
                        }

                        &.full-cell {
                            &:before {
                                background: #d433398f;
                            }
                        }

                        &.avail-cell {
                            &:before {
                                background: #2cb36085;
                            }
                        }

                        &.partial-cell {
                            &:before {
                                background: #f9ce3c94;
                            }
                        }
                    }
                }

                .week-days-wrap {
                    display: flex;
                    border-bottom: 1px solid #e8e8e8;

                    .week-day {
                        display: flex;
                        flex-direction: column;
                        padding: 15px 0;
                        width: 52px;
                        text-align: center;
                        font-weight: bold;
                        border-right: 1px solid #e2e2e2;

                        span {
                            &:last-child {
                                color: #8d8d8d;
                                margin-top: 4px;
                                font-size: 14px;
                            }
                        }
                    }

                    .week-day-selected {
                        display: flex;
                        flex-direction: column;
                        padding: 15px 0;
                        width: 52px;
                        text-align: center;
                        font-weight: bold;
                        border-right: 1px solid #e2e2e2;
                        background-color: #2a324b;
                        border-radius: 6px 6px 0px 0px;

                        span {
                            color: #dbdce1;

                            &:last-child {
                                margin-top: 4px;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }

            &:not(:first-child) .week-content {
                border-left: 2px solid #cbcbcb;
            }

            .week-number {
                margin-left: 3px;
                font-weight: 800;
                color: #002340;
                font-size: 17px;
                margin-bottom: 10px;
            }

            &:first-child .week-data-column:first-child .data-cell:before {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }

            &:last-child .week-data-column:last-child .data-cell:before {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }
        }
    }
}

.btn-calendar {
    background: transparent;
    color: #2a324b;
    cursor: pointer;
}

.occupancy-date-time-selector {
    height: 0;

    input {
        width: 41px;
        height: 0;
        visibility: hidden;
        z-index: -1;
        margin: 0px;
    }
}

@media (max-width: 992px) {
    .occupancy-calendar-wrap .calendar-sidebar-wrap {
        width: 195px;

        .cars-wrap {
            width: 165px;
        }
    }

    .weeks-wrap {
        overflow-y: auto;
    }
}