.section-title {
    text-align: center;
    font-weight: 600;
    margin: 35px 0;
}

.form-error {
    font-weight: 400;
    font-size: 14px;
    margin: 3px 3px;
    color: red;
}

.action-btn {
        font-size: 1.1em !important;
        font-weight: 600 !important;
}

.button-loader {
    width: 20px;
    height: 20px;
    /* float: left; */
    margin: 0 3px;
}

.down-arrow {
    width: 10px;
    height: 10px;
    float: right;
    margin: 0 3px;
}

#form-control {
    border: 0;
}

.page-title {
    text-align: center;
    margin: 0;
    font-size: 31px;
    font-weight: 600;
    line-height: 1;
    padding-bottom: 20px;
}

.rdtPicker {
    color: #0f0f10 !important;
    font-size: 14px !important;
}

.cursor-pointer {
    cursor: pointer;
}

td.max-150 {
    max-width: 150px;
}

/// <-- Font classes --> ///
.font-size-13 {
    font-size: 13px;
}

.font-size-14 {
    font-size: 14px;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.font-size-22 {
    font-size: 22px !important;
}
.font-size-26 {
    font-size: 26px !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.font-weight-bold {
    font-weight: bold !important;
}

.font-weight-900 {
    font-weight: 900 !important;
}

/// <--/ Font classes --> ///

/// <-- Text Align classes --> ///
.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.text-justify {
    text-align: justify;
}

/// <--/ Text Align classes --> ///

.float-right {
    float: right;
}

.height-100vh {
    height: 100vh;
}

.card-tooltip-wrap {
    position: absolute;
    bottom: -55px;
    padding-top: 40px;
    z-index: 1000;
    left: 0;

    .card-tooltip {
        position: relative;
        padding: 10px;
        background: white;
        width: 280px;
        display: flex;
        justify-content: space-around;
        border-radius: 4px;

        &:before {
            content: "";
            background: #ffffff00;
            position: absolute;
            top: -7px;
            left: 50%;
            border-right: 7px solid transparent;
            border-left: 7px solid transparent;
            border-bottom: 7px solid white;
            z-index: 1000;
        }

        &:after {
            position: absolute;
            top: -6px;
            left: 50%;
            content: "";
            border-bottom: 6px solid white;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
        }
    }
}

.no-result-wrap {
    padding: 10px;
    background: #eceef3;
    border: 1px solid #e2e2e2;
    border-radius: 5px;
    text-align: center;
    font-size: 14px;
}

.quoteRequestModalDialog {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
}

/// <-- Margins --> ///

.mr-0 {
    margin-right: 0 !important;
}

.search-select-wrap {
    position: relative;

    select {
        border: none;
        min-height: 52px;
    }

}

.total-rows-selector {
    height: 100%;
    position: relative;
    display: inline-block;
    width: 100px;
    margin: 0 5px;

    select {
        height: auto !important;
        border: none;
    }

    &:before {
        content: "";
        width: 10px;
        height: 10px;
        top: 21px;
        position: absolute;
        right: 12px;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.vehicle-info-wrap {
    .tab-content {
        color: black !important;
        background: #f6f6f6;
        border-radius: 4px;
        padding: 6px 9px;
        font-size: 16px;
        font-weight: 500;
    }

    a.nav-link,
    button.nav-link {
        color: black !important;
        border: 1px solid #cacaca;
        margin-right: 3px;
        padding: 0.5rem !important;
        cursor: pointer;

        &.active {
            background: #f6f6f6 !important;
            border-color: #dee2e6 #dee2e6;
            border-bottom: none;
        }
    }
}

.normal-link {
    text-decoration: underline;
    color: #2a324b;
    cursor: pointer;
}

.form-extras {
    font-size: 14px;
    line-height: 29px;
    padding-top: 30px;
    margin: 33px 0 !important;
    text-align: center;
    position: relative;

    &::before {
        content: '';
        width: 40%;
        background: #dbdbdb;
        position: absolute;
        top: 0;
        height: 1px;
        left: 0;
    }

    &::after {
        content: '';
        width: 40%;
        background: #dbdbdb;
        position: absolute;
        top: 0;
        height: 1px;
        right: 0;
    }

    span {
        position: absolute;
        top: -15px;
        width: 100%;
        left: 0;
        color: #343434;
    }

    button {
        width: 200px;
    }

    a {
        cursor: pointer;

        &:not(.text-danger) {
            font-size: 15px;
            color: #1e40a8 !important;
        }
    }
}

.overflow-x-hidden {
    overflow-x: hidden;
}

//// Wordpress styles
.wp-block-group {
    box-sizing: border-box;
}

:where(.wp-block-group.has-background) {
    padding: 1.25em 2.375em;
}

h1.has-background,
h2.has-background,
h3.has-background,
h4.has-background,
h5.has-background,
h6.has-background {
    padding: 1.25em 2.375em;
}

.is-small-text {
    font-size: 0.875em;
}

.is-regular-text {
    font-size: 1em;
}

.is-large-text {
    font-size: 2.25em;
}

.is-larger-text {
    font-size: 3em;
}

.has-drop-cap:not(:focus):first-letter {
    float: left;
    font-size: 8.4em;
    line-height: 0.68;
    font-weight: 100;
    margin: 0.05em 0.1em 0 0;
    text-transform: uppercase;
    font-style: normal;
}

p.has-drop-cap.has-background {
    overflow: hidden;
}

p.has-background {
    padding: 1.25em 2.375em;
}

:where(p.has-text-color:not(.has-link-color)) a {
    color: inherit;
}

.wp-block-button__link {
    color: #fff;
    background-color: #32373c;
    border-radius: 9999px;
    box-shadow: none;
    cursor: pointer;
    display: inline-block;
    font-size: 1.125em;
    padding: calc(0.667em + 2px) calc(1.333em + 2px);
    text-align: center;
    text-decoration: none;
    word-break: break-word;
    box-sizing: border-box;
}

.wp-block-button__link:active,
.wp-block-button__link:focus,
.wp-block-button__link:hover,
.wp-block-button__link:visited {
    color: #fff;
}

.wp-block-button__link.aligncenter {
    text-align: center;
}

.wp-block-button__link.alignright {
    text-align: right;
}

.wp-block-buttons>.wp-block-button.has-custom-width {
    max-width: none;
}

.wp-block-buttons>.wp-block-button.has-custom-width .wp-block-button__link {
    width: 100%;
}

.wp-block-buttons>.wp-block-button.has-custom-font-size .wp-block-button__link {
    font-size: inherit;
}

.wp-block-buttons>.wp-block-button.wp-block-button__width-25 {
    width: calc(25% - var(--wp--style--block-gap, 0.5em) * 0.75);
}

.wp-block-buttons>.wp-block-button.wp-block-button__width-50 {
    width: calc(50% - var(--wp--style--block-gap, 0.5em) * 0.5);
}

.wp-block-buttons>.wp-block-button.wp-block-button__width-75 {
    width: calc(75% - var(--wp--style--block-gap, 0.5em) * 0.25);
}

.wp-block-buttons>.wp-block-button.wp-block-button__width-100 {
    width: 100%;
    flex-basis: 100%;
}

.wp-block-buttons.is-vertical>.wp-block-button.wp-block-button__width-25 {
    width: 25%;
}

.wp-block-buttons.is-vertical>.wp-block-button.wp-block-button__width-50 {
    width: 50%;
}

.wp-block-buttons.is-vertical>.wp-block-button.wp-block-button__width-75 {
    width: 75%;
}

.wp-block-button.is-style-squared,
.wp-block-button__link.wp-block-button.is-style-squared {
    border-radius: 0;
}

.wp-block-button.no-border-radius,
.wp-block-button__link.no-border-radius {
    border-radius: 0 !important;
}

.is-style-outline> :where(.wp-block-button__link),
:where(.wp-block-button__link).is-style-outline {
    border: 2px solid;
    padding: 0.667em 1.333em;
}

.is-style-outline>.wp-block-button__link:not(.has-text-color),
.wp-block-button__link.is-style-outline:not(.has-text-color) {
    color: currentColor;
}

.is-style-outline>.wp-block-button__link:not(.has-background),
.wp-block-button__link.is-style-outline:not(.has-background) {
    background-color: transparent;
}

.wp-block-buttons.is-vertical {
    flex-direction: column;
}

.wp-block-buttons.is-vertical>.wp-block-button:last-child {
    margin-bottom: 0;
}

.wp-block-buttons>.wp-block-button {
    display: inline-block;
    margin: 0;
}

.wp-block-buttons.is-content-justification-left {
    justify-content: flex-start;
}

.wp-block-buttons.is-content-justification-left.is-vertical {
    align-items: flex-start;
}

.wp-block-buttons.is-content-justification-center {
    justify-content: center;
}

.wp-block-buttons.is-content-justification-center.is-vertical {
    align-items: center;
}

.wp-block-buttons.is-content-justification-right {
    justify-content: flex-end;
}

.wp-block-buttons.is-content-justification-right.is-vertical {
    align-items: flex-end;
}

.wp-block-buttons.is-content-justification-space-between {
    justify-content: space-between;
}

.wp-block-buttons.aligncenter {
    text-align: center;
}

.wp-block-buttons:not(.is-content-justification-space-between, .is-content-justification-right, .is-content-justification-left, .is-content-justification-center) .wp-block-button.aligncenter {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.wp-block-button.aligncenter {
    text-align: center;
}

.wp-block-media-text {
    /*!rtl:begin:ignore*/
    direction: ltr;
    /*!rtl:end:ignore*/
    display: grid;
    grid-template-columns: 50% 1fr;
    grid-template-rows: auto;
}

.wp-block-media-text.has-media-on-the-right {
    grid-template-columns: 1fr 50%;
}

.wp-block-media-text.is-vertically-aligned-top .wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-top .wp-block-media-text__media {
    align-self: start;
}

.wp-block-media-text.is-vertically-aligned-center .wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-center .wp-block-media-text__media,
.wp-block-media-text .wp-block-media-text__content,
.wp-block-media-text .wp-block-media-text__media {
    align-self: center;
}

.wp-block-media-text.is-vertically-aligned-bottom .wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-bottom .wp-block-media-text__media {
    align-self: end;
}

.wp-block-media-text .wp-block-media-text__media {
    /*!rtl:begin:ignore*/
    grid-column: 1;
    grid-row: 1;
    /*!rtl:end:ignore*/
    margin: 0;
}

.wp-block-media-text .wp-block-media-text__content {
    direction: ltr;
    /*!rtl:begin:ignore*/
    grid-column: 2;
    grid-row: 1;
    /*!rtl:end:ignore*/
    padding: 0 8%;
    word-break: break-word;
}

.wp-block-media-text.has-media-on-the-right .wp-block-media-text__media {
    /*!rtl:begin:ignore*/
    grid-column: 2;
    grid-row: 1;
    /*!rtl:end:ignore*/
}

.wp-block-media-text.has-media-on-the-right .wp-block-media-text__content {
    /*!rtl:begin:ignore*/
    grid-column: 1;
    grid-row: 1;
    /*!rtl:end:ignore*/
}

.wp-block-media-text__media img,
.wp-block-media-text__media video {
    height: auto;
    max-width: unset;
    width: 100%;
    vertical-align: middle;
}

.wp-block-media-text.is-image-fill .wp-block-media-text__media {
    height: 100%;
    min-height: 250px;
    background-size: cover;
}

.wp-block-media-text.is-image-fill .wp-block-media-text__media>a {
    display: block;
    height: 100%;
}

.wp-block-media-text.is-image-fill .wp-block-media-text__media img {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

@media (max-width: 600px) {
    .wp-block-media-text.is-stacked-on-mobile {
        grid-template-columns: 100% !important;
    }

    .wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__media {
        grid-column: 1;
        grid-row: 1;
    }

    .wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__content {
        grid-column: 1;
        grid-row: 2;
    }
}

//// End of Wordpress styles
input.read-only-gray:read-only {
    background: #e9ecef;
}


.z-index-1000 {
    z-index: 1000;
}


.h-100 {
    height: 100% !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.mr-2 {
    margin-right: 0.5rem;
}

.mr-4 {
    margin-right: 1.5rem;
}

.mb-4 {
    margin-bottom: 1.5rem;
}

.pr-0 {
    padding-right: 0 !important;
}

.pl-3 {
    padding-left: 3rem !important;
}

.accept-terms {
    font-size: 19px !important;

    .form-check-label {
        margin-top: 4px;
        font-size: 15px !important;
    }
}

.date-picker-wrap {
    .react-datepicker-wrapper {
        width: 100%;
    }
}

.mb-8-rem {
    margin-bottom: 8rem !important;
}

.checkbox-size-md {
    font-size: 19px !important;

    label {
        font-size: 15px !important;
    }
}

.checkbox-size-lg {
    font-size: 25px !important;

    label {
        font-size: 19px !important;
    }
}

.top-border-separator {
    border-top: 1px solid rgb(197, 197, 197);
}

.number-circle {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    line-height: 15px;
    padding-top: 3px;

    background: red;
    color: white;
    text-align: center;

    font-size: 10px;

    position: absolute;
    right: 15px;
    top: 10px;
}

.non-visible {
    visibility: hidden;
}

.border-top-dashed {
    border-top: 1px dashed #d9d9d9
}