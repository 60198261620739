.car-name-wrap {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #767b91;
}

.day-name-wrap {
    text-align: center;
    font-size: 31px;
    font-weight: bold;
    border-bottom: 1px solid #ececec;
    margin-bottom: 30px;
    padding-bottom: 25px;
}

.occupancy-card-wrap {
    position: absolute;
    z-index: 2;
    width: 1104px;
    box-shadow: 1px 1px 6px 2px #00000014;
    border-radius: 10px;
}

.arrow-up {
    content: "";
    position: absolute;
    width: 0px;
    height: 0;
    margin-left: -0.5em;
    left: 45px;
    bottom: -28px;
    z-index: 10;
    box-sizing: border-box;
    border: 9px solid black;
    border-color: transparent transparent #ffffff #ffffff;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -2px 2px 3px 0px #00000014;
}

.daily-occupancy-wrap {
    display: flex;

    .occupancy-statuses-wrap {
        width: 72%;
        background: #c6e4d1;
        border-radius: 6px;
        position: relative;

        .occupancy-status-item {
            position: absolute;
            width: 100%;

            &:hover {
                .occupancy-status-popup {
                    display: block !important;
                }
            }

            .occupancy-status-popup {
                background: white;
                border-radius: 8px;
                width: 80%;
                left: 25px;
                position: absolute;
                padding: 16px 18px;
                font-size: 14px;
                top: 15px;
                display: none;
                z-index: 1000;
                text-align: center;
                span{
                    background-color: transparent !important;
                }
                &:before {
                    content: '';
                    width: 0;
                    height: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-bottom: 5px solid white;
                    position: absolute;
                    top: -5px;
                }
            }
        }

        .occupancy-status {
            padding: 4px 8px;
            justify-content: space-between;
            display: flex;
            width: 100%;
            height: 100%;

            span {
                &:first-child {
                    font-weight: bold;
                    font-size: 14px;
                }

                &:last-child {
                    font-size: 13px;
                    font-weight: 300;
                }
            }

        }

        .occupancy-full {
            background: #f4c8c9;
            color: #b40000;
        }

        .occupancy-avail {
            background: #c6e4d1;
            color: #03742a;
        }

        .occupancy-partly {
            background: #fbecbc;
            color: #8d6f0c;
        }

        .occupancy-locked {
            background: #c6cfe9;
            color: #1e40a8;
        }

        .occupancy-manually-locked {
            background: #e4c6e9;
            color: #a11ea8;
        }

        .occupancy-standby {
            background: #ff9933;
            color: #b40000;
        }
        .occupancy-external-locked {
            background: #efa7be;
            color: #9a3a58;
        }
        .occupancy-undefined {
            background: #d7d7d7;
            color: #270d0d;
        }

        span {
            &.remove-lockout {
                width: 100%;
                text-align: center;
                display: block;
                color: rgb(202, 0, 0);
                font-weight: 500;

                &:hover {
                    color: red;
                    transition: all 0.2s;
                    transform: scale(1.1);
                }
            }


        }

        

        
    }

    .occupancy-row {
        .occupancy-time {
            text-align: center;
            font-size: 13px;
            padding: 13px;
            box-sizing: revert;
            border-bottom: 1px solid #eaeaea;
        }


        &:last-child {
            .occupancy-time {
                border: none !important;
            }
        }
    }
}

.occupancy-form-wrap {
    padding: 10px 6%;
}

.search-dropdown {
    left: 0 !important;
    top: 58px !important;
}

.btn-card-close {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
}

.weekdays-wrap {
    display: flex;
    padding: 0;
    margin-top: 20px;
    justify-content: center;

    div.weekday {
        background: #eff1f5;
        margin: 2px 4px;
        font-size: 13px;
        padding: 4px 8px;
        border-radius: 17px;

        &:hover {
            transition: all 0.2s;
            border: 1px solid #8ebc9f;
        }

        &.selected {
            border: 1px solid #8ebc9f;
            background: #c6e4d1 !important;
        }
    }
}